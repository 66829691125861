"use client";

import React from "react";
import {Trans, useTranslation} from "react-i18next";
import Image from "next/image";
import {MdLooks3, MdLooks4, MdLooksOne, MdLooksTwo} from "react-icons/md";

const Section3 = () => {
  const { t } = useTranslation();

  return (
    <div className="sec-3 mx-auto max-w-6xl px-4 py-[60px]">
      <div className="grid grid-cols-12 gap-y-14 md:gap-x-14">
        <div className="md:col-span-6 col-span-12 relative md:order-1 order-2">
          <div className="relative w-full">
            <Image
              alt="how it works"
              loading="lazy"
              width="560"
              height="420"
              className="h-[224px] rounded-xl object-cover md:h-[420px]"
              src="https://cdn.1cdn.app/application/CLEARASSURE/25021311192173_how_it_works.jpg"
            />
          </div>
        </div>
        <div className="md:col-span-6 col-span-12 md:order-2 order-1">
          <h2 className="mb-[14px] text-[32px] font-bold leading-9">
            {t("Home.Section3.key1")}
          </h2>

          <p className="mb-6 text-base">
            <Trans i18nKey={t("Home.Section3.key5")} />
          </p>

          <div className="flex flex-col gap-[22px]">
            <div className="flex items-center gap-2.5">
                <MdLooksOne size={40} color="#2f8ac2" className="shrink-0"/>
              <span className="text-base">
                <Trans i18nKey={t("Home.Section3.key6")} />
              </span>
            </div>
            <div className="flex items-center gap-2.5">
              <MdLooksTwo size={40} color="#2f8ac2" className="shrink-0"/>
              <span className="text-base">
                <Trans i18nKey={t("Home.Section3.key7")} />
              </span>
            </div>
            <div className="flex items-center gap-2.5">
              <MdLooks3 size={40} color="#2f8ac2" className="shrink-0"/>
              <span className="text-base">
                <Trans i18nKey={t("Home.Section3.key8")} />
              </span>
            </div>
            <div className="flex items-center gap-2.5">
              <MdLooks4 size={40} color="#2f8ac2" className="shrink-0"/>
              <span className="text-base">
                <Trans i18nKey={t("Home.Section3.key9")} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
