"use client";

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  BiBrain,
  BiCheckShield,
  BiLock,
  BiMoney,
  BiSearchAlt,
} from "react-icons/bi";

const Section11 = () => {
  const { t } = useTranslation();

  const handleScrollToRelevantSection = () => {
    const el = document.getElementById("services-area");

    if (el) {
      const scrollPosition = el.offsetTop - 100;

      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="sec-7 mx-auto max-w-6xl flex-col md:flex md:px-0 px-4 mb-6">
      <div className="relative overflow-hidden rounded-[20px] bg-[#F9FAFD]">
        <div className="pointer-events-none absolute -right-1/3 z-10 h-full w-full rounded-full theme-bg-color opacity-30 blur-[600px]"></div>
        <div className="flex flex-col items-center rounded-[20px] px-[34px] py-[24px]">
          <div className="flex w-full md:flex-row flex-col items-center md:gap-20 gap-6">
            <div className="flex flex-1 flex-col gap-2.5 md:order-1 order-1">
              <span className="text-[34px] font-bold leading-[44px] tracking-[-0.68px]">
                {t("Home.Section11.key1")}
              </span>
            </div>
            <ul className="flex flex-1 flex-col gap-2.5 md:order-2 order-3">
              <li className="flex items-center gap-2.5 tw-h-24">
                <span className="text-base font-medium tw-grow">
                  <Trans i18nKey={t("Home.Section11.key2")} />
                </span>
              </li>
              <li className="flex items-center gap-2.5 tw-h-24">
                <span className="text-base font-medium tw-grow">
                  <Trans i18nKey={t("Home.Section11.key3")} />
                </span>
              </li>
              <li className="flex items-center gap-2.5 tw-h-24">
                <span className="text-base font-medium tw-grow">
                  <Trans i18nKey={t("Home.Section11.key4")} />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section11;
