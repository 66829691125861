"use client";

import React from "react";
import { useTranslation } from "react-i18next";
import {MdLooks3, MdLooks4, MdLooks5, MdLooksOne, MdLooksTwo} from "react-icons/md";

const Section7 = () => {
  const { t } = useTranslation();

  const handleScrollToRelevantSection = () => {
    const el = document.getElementById("services-area");

    if (el) {
      const scrollPosition = el.offsetTop - 100;

      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  return (
      <div className="sec-7 mx-auto max-w-6xl flex-col md:flex md:px-0 px-4">
        <div className="relative overflow-hidden rounded-[20px] bg-[#F9FAFD]">
          <div className="pointer-events-none absolute -right-1/3 z-10 h-full w-full rounded-full theme-bg-color opacity-30 blur-[600px]"></div>
          <div className="flex flex-col items-center rounded-[20px] px-[34px] py-[24px]">
            <div className="flex w-full md:flex-row flex-col items-center md:gap-20 gap-6">
              <div className="flex flex-1 flex-col gap-2.5 md:order-1 order-1">
          <span className="text-[34px] font-bold leading-[44px] tracking-[-0.68px]">
            {t("Home.Section7.key1")}
          </span>
                <p className="text-[16px] font-medium leading-[26px]">
                  {t("Home.Section7.key2")}
                </p>
              </div>
              <ul className="flex flex-1 flex-col gap-2.5 md:order-2 order-3">
                <li className="flex items-center gap-2.5">
                  <MdLooksOne size={40} color="#2f8ac2" className="shrink-0"/>
                  <span className="text-base font-medium">
              {t("Home.Section7.key3")}
            </span>
                </li>
                <li className="flex items-center gap-2.5">
                  <MdLooksTwo size={40} color="#2f8ac2" className="shrink-0"/>
                  <span className="text-base font-medium">
              {t("Home.Section7.key4")}
            </span>
                </li>
                <li className="flex items-center gap-2.5">
                  <MdLooks3 size={40} color="#2f8ac2" className="shrink-0"/>
                  <span className="text-base font-medium">
              {t("Home.Section7.key5")}
            </span>
                </li>
                <li className="flex items-center gap-2.5">
                  <MdLooks4 size={40} color="#2f8ac2" className="shrink-0"/>
                  <span className="text-base font-medium">
              {t("Home.Section7.key6")}
            </span>
                </li>
                <li className="flex items-center gap-2.5">
                  <MdLooks5 size={40} color="#2f8ac2" className="shrink-0"/>
                  <span className="text-base font-medium">
              {t("Home.Section7.key7")}
            </span>
                </li>
              </ul>
              <button
                  onClick={handleScrollToRelevantSection}
                  className="md:hidden block md:order-3 order-2 rounded-[56px] text-base font-semibold ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-center bg-thirdy text-white hover:bg-thirdy/90 h-[56px] px-[26px] md:mt-[34px]"
              >
                {t("Home.Section7.cta")}
              </button>
            </div>
            <button
                onClick={handleScrollToRelevantSection}
                className="md:block hidden md:order-3 order-2 rounded-[56px] text-base font-semibold ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-center bg-thirdy text-white hover:bg-thirdy/90 h-[56px] px-[26px] md:mt-[34px]"
            >
              {t("Home.Section7.cta")}
            </button>
          </div>
        </div>
      </div>

  );
};

export default Section7;
