"use client";

import { useAppDispatch } from "@/lib/hooks";
import Image from "next/image";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {BiBody, BiCar, BiHeart, BiHome, BiMoneyWithdraw, BiTargetLock} from "react-icons/bi";
import { FaBalanceScale, FaUmbrellaBeach } from "react-icons/fa";

const Section1 = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const scrollToSection = (targetSection: string) => {
    const section = document.getElementById(targetSection);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const services: Array<{
    href: string;
    icons: React.ReactNode;
    title: string;
  }> = [
    {
      href: "/services/assurance-voiture",
      icons: (
        <div className="flex-shrink-0">
          <BiCar size={30} />
        </div>
      ),
      title: t("Home.Section1.key4"),
    },
    {
      href: "/services/assurance-sante",
      icons: (
        <div className="flex-shrink-0">
          <BiHeart size={30} />
        </div>
      ),
      title: t("Home.Section1.key5"),
    },
    {
      href: "/services/protection-juridique",
      icons: (
        <div className="flex-shrink-0">
          <FaBalanceScale size={30} />
        </div>
      ),
      title: t("Home.Section1.key6"),
    },
    {
      href: "/services/prevoyance",
      icons: (
        <div className="flex-shrink-0">
          <FaUmbrellaBeach size={30} />
        </div>
      ),
      title: t("Home.Section1.key7"),
    },
    {
      href: "/services/assurance-menage",
      icons: (
        <div className="flex-shrink-0">
          <BiHome size={30} />
        </div>
      ),
      title: t("Home.Section1.key8"),
    },
    {
      href: "/services/hypotheque",
      icons: (
        <div className="flex-shrink-0">
          <BiMoneyWithdraw size={30} />
        </div>
      ),
      title: t("Home.Section1.key9"),
    },
  ];

  return (
    <React.Fragment>
      <div className="sec-1 theme-bg-color">
        <div className="max-w-6xl mx-auto md:px-0 px-4">
          <div className="grid grid-cols-12 items-center md:gap-1">
            <div className="md:col-span-6 col-span-12 pt-[44px] md:py-[107px] md:order-1 order-1">
              <div className="mb-[30px] md:mb-[26px] text-white">
                <div className="min-h-9 px-4 bg-[#d62280] justify-center items-center gap-2.5 inline-flex mb-6">
                  <div className="text-center text-white text-2xl font-extrabold font-['Work Sans'] leading-9">
                    {t("Home.Section1.key1")}
                  </div>
                </div>
                <h1 className="text-[22px] font-semibold leading-[38px] md:text-[26px] md:leading-[40px]">
                  <span className="text-[34px] font-bold md:text-[40px]">
                    <Trans i18nKey={t("Home.Section1.key2")} />
                  </span>
                </h1>
                <p className="text-[16px] md:text-[20px] font-normal">
                  {t("Home.Section1.key3")}
                </p>
                <button
                  className="mt-10 shrink-0 items-center justify-center whitespace-nowrap rounded-[56px] text-base font-semibold ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-center bg-white text-black hover:bg-white/90 h-[50px] px-[21px] w-fit flex"
                  onClick={() => scrollToSection("services-area")}
                >
                  <BiBody size={20} />
                  <span className="inline-flex">{t("Home.Section1.cta")}</span>
                </button>
              </div>

              {/* <div className="mb-[28px] hidden w-fit grid-cols-3 gap-[14px] md:grid xl:grid-cols-3 md:grid-cols-2 services-items">
                {services.map((service) => (
                  <Link
                    key={service.href}
                    rel="next"
                    className="card-shadow flex h-[136px] w-[160px] flex-col items-center justify-center gap-2.5 rounded-xl bg-white transition-all hover:scale-105 hover:bg-neutral-50 hover:shadow-xl"
                    href={service.href}
                  >
                    {service.icons}
                    <span className="text-center text-[14px] font-medium">
                      {service.title}
                    </span>
                  </Link>
                ))}
              </div> */}
            </div>
            <div className="md:col-span-6 col-span-12 md:order-2 order-2 relative">
              <div className="md:min-h-[400px] min-h-[420px] relative">
                {/* Image here as fit with object-contain */}
                <Image
                  src="https://cdn.1cdn.app/application/CLEARASSURE/25021311124220_banner.png"
                  alt="hero"
                  width="629"
                  height="455"
                  //fill
                  style={{ objectFit: "scale-down" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Small screen service items */}
      {/* <div className="w-full bg-[#FCFCFC] md:hidden px-4">
        <div className="mx-auto grid w-fit translate-y-[-48px] grid-cols-2 gap-[14px]">
          {services.map((service) => (
            <Link
              key={service.href}
              rel="next"
              className="card-shadow flex h-[136px] w-[160px] flex-col items-center justify-center gap-2.5 rounded-xl bg-white transition-all hover:scale-105 hover:bg-neutral-50 hover:shadow-xl"
              href={service.href}
            >
              {service.icons}
              <span className="text-center text-[14px] font-medium">
                {service.title}
              </span>
            </Link>
          ))}
        </div>
      </div> */}
    </React.Fragment>
  );
};

export default Section1;
