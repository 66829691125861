"use client";

import Image from "next/image";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const Section8 = () => {
  const { t } = useTranslation();

  return (
    <div className="sec-8 relative overflow-hidden py-14 md:py-20">
      <div className="pointer-events-none absolute top-0 -z-10 h-full w-full rounded-full bg-[#FDF6D1] opacity-30 blur-[750px]"></div>
      <div className="mx-auto flex max-w-6xl flex-col items-center justify-center">
        <h2 className="mb-10 text-center text-[33px] font-bold leading-9 md:text-[34px]">
          <Trans i18nKey={"Home.Section8.key1"} />
        </h2>
        <div className="w-full space-y-[38px] px-2 md:space-y-[100px]">
          <div className="flex flex-col items-center gap-6 px-4 md:gap-8 md:flex-row">
            <Image
              alt="Car insurance image"
              loading="lazy"
              width="560"
              height="420"
              className="h-[224px] rounded-xl object-cover md:h-[420px] md:w-1/2"
              src="https://cdn.1cdn.app/application/CLEARASSURE/25021311124240_assurance_auto.jpg"
            />
            <div className="mb-3 flex w-full flex-col md:w-1/2">
              <h4 className="text-[26px] font-bold">
                {t("Home.Section8.key2")}
              </h4>
              <div className="mb-6 text-base font-medium">
                <p>
                  {t("Home.Section8.key3")} <br />
                  <br />
                  {t("Home.Section8.key4")}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center gap-6 px-4 md:gap-8 md:flex-row-reverse">
            <Image
              alt="Health insurance image"
              loading="lazy"
              width="560"
              height="420"
              className="h-[224px] rounded-xl object-cover md:h-[420px] md:w-1/2"
              src="https://cdn.1cdn.app/application/CLEARASSURE/25021311192243_mutuelle_sante.jpg"
            />
            <div className="mb-3 flex w-full flex-col md:w-1/2">
              <h4 className="text-[26px] font-bold">
                {t("Home.Section8.key5")}
              </h4>
              <div className="mb-6 text-base font-medium">
                <p>
                  {t("Home.Section8.key6")} <br />
                  <br />
                  {t("Home.Section8.key7")}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center gap-6 px-4 md:gap-8 md:flex-row">
            <Image
              alt="Home insurance image"
              loading="lazy"
              width="560"
              height="420"
              className="h-[224px] rounded-xl object-cover md:h-[420px] md:w-1/2"
              src="https://cdn.1cdn.app/application/CLEARASSURE/25021311124273_assurance_habitation.jpg"
            />
            <div className="mb-3 flex w-full flex-col md:w-1/2">
              <h4 className="text-[26px] font-bold">
                {t("Home.Section8.key8")}
              </h4>
              <div className="mb-6 text-base font-medium">
                <p>
                  {t("Home.Section8.key9")} <br />
                  <br />
                  {t("Home.Section8.key10")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section8;
