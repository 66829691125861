"use client";

import React from "react";
import { useTranslation } from "react-i18next";
import {BiCheckboxChecked} from "react-icons/bi";
import {FaBalanceScale, FaUserCircle} from "react-icons/fa";

const Section2 = () => {
  const { t } = useTranslation();

  return (
    <div className="sec-2 w-full bg-[#F9FCFC]">
      <div className="mx-auto grid w-full max-w-6xl grid-cols-1 place-items-center justify-between gap-[38px] py-[60px] md:grid-cols-3 md:gap-6">
        <div className="flex flex-col items-center">
          <div className="mb-2 w-fit rounded-full theme-bg-color p-[19px] text-white">
            <FaUserCircle size={40} />
          </div>
          <h3 className="text-center text-lg font-bold">
            {t("Home.Section2.key1")}
          </h3>
          <p className="text-center text-base">{t("Home.Section2.key2")}</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="mb-2 w-fit rounded-full theme-bg-color p-[19px] text-white">
            <FaBalanceScale size={40} />
          </div>
          <h3 className="text-center text-lg font-bold">
            {t("Home.Section2.key3")}
          </h3>
          <p className="text-center text-base">{t("Home.Section2.key4")}</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="mb-2 w-fit rounded-full theme-bg-color p-[19px] text-white">
            <BiCheckboxChecked size={40} />
          </div>
          <h3 className="text-center text-lg font-bold">
            {t("Home.Section2.key5")}
          </h3>
          <p className="text-center text-base">{t("Home.Section2.key6")}</p>
        </div>
      </div>
    </div>
  );
};

export default Section2;
